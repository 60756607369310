// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const isPreLaunchMode = true

export const environment = {
    production: false,
    url: {
        gen1: {
            base: 'https://us-central1-readymaster-development.cloudfunctions.net/',
        },
        gen2: {
            prefix: 'https://',
            sufix: 'uc.a.run.app',
            project: 'd7qegcmwxa',
        },
        // TODO: remove next entry?
        project: 'xtn3g5n53a',
    },
    projectId: "TnEsiSmGaWulc9IUL9ge",
    dashboard: {
      apiKey: "AIzaSyD9BpZ95WnMczVXT7mgVIxg1U4KrVq-mpY",
      authDomain: "player-dashboard-q1f.firebaseapp.com",
      projectId: "player-dashboard-q1f",
      storageBucket: "player-dashboard-q1f.appspot.com",
      messagingSenderId: "270663735487",
      appId: "1:270663735487:web:3d70dfe0f076bdd232ce0a",
      measurementId: "G-C7C50QHH67"
    },
    firebaseMaster: {
      apiKey: "AIzaSyBJ212bey2FAfhN-JZpw4eHi8loM7XUdW4",
      authDomain: "readymaster-development.firebaseapp.com",
      databaseURL: "https://readymaster-development-default-rtdb.firebaseio.com",
      projectId: "readymaster-development",
      storageBucket: "readymaster-development.appspot.com",
      messagingSenderId: "767053156338",
      appId: "1:767053156338:web:480511463f746e3664a56b",
      measurementId: "G-BSGX9EY4TW"
    },
    firestoragePath: "gs://readymaster-development.appspot.com",
    firebaseTestWebAppProxyCX: {
        apiKey: "AIzaSyBQipqHOVSSQGWiVzO2DAIqd1pqNt9Zy90",
        authDomain: "nouveauproxycx.firebaseapp.com",
        projectId: "nouveauproxycx",
        storageBucket: "nouveauproxycx.appspot.com",
        messagingSenderId: "101136790880",
        appId: "1:101136790880:web:180504b470ed135c36da05"
    },
    readyGamesDeveloper: {
        apiKey: "AIzaSyAgd55UOvbzWyHS1FT6N96luxk3jSpF37A",
        authDomain: "rgn-web-live.firebaseapp.com",
        projectId: "rgn-web-live",
        storageBucket: "rgn-web-live.appspot.com",
        messagingSenderId: "957370161894",
        appId: "1:957370161894:web:5d1cb948e8d77df037f927",
        measurementId: "G-9GQWSR62T5"
    },
    smart_contract: {
        chain: 'polygon',
        adminAddress: "0x4c6348bf16FeA56F3DE86553c0653b817bca799A",
        auraAddress: "0xbDDCc0E86564B4A8Eb1df352CFb2B7629488Dd96",
        fuseBlockAddress: "0x95959432293943D77Ed3e2eA065E9D65A21d0227",
        itemAddress: "0x668d923D4be2ffF8EC7645a1E5Cebb57102493Ba",
        stakeAddress: "0x479644b1c2fbbB1713ef56038b26292609554189",
    },
    auth: {
        url: 'https://development-oauth.myplay.network'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
